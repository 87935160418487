import React, { useState } from "react";
import Image1 from "../../assets/images/Untitled-1.jpg";
import Image2 from "../../assets/images/MG_3294.jpg";
import Image3 from "../../assets/images/MG_3509.jpg";
import Image4 from "../../assets/images/MG_1795.jpg";
import Image5 from "../../assets/images/crew.jpg";
import Image6 from "../../assets/images/444.jpg";
import Image7 from "../../assets/images/ADI_3.jpg";
import Image9 from "../../assets/images/MG_6254.jpg";
import Image8 from "../../assets/images/MG_5762.jpg";
import "./imagesContainer.css";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ImagesContainer = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const imagesArray = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
  ];
  const getImageSize = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    return { width: img.width, height: img.height, src: img.src };
  };

  const images = imagesArray.map((image) => getImageSize(image));

  return (
    <div className="image_container">
      {images?.map((image, index) => (
        <img
          className="image_sc"
          src={image.src}
          alt="img"
          key={index}
          style={{
            aspectRatio: `${image.width}/${image.height}`,
          }}
          onClick={() => {
            setShowPreview(true);
            setPreviewImage(image.src);
          }}
        />
      ))}
      {showPreview && (
        <div className="image_preview_container">
          <button onClick={() => setShowPreview(false)}>
            <HighlightOffIcon />
          </button>
          <img className="image_preview" src={previewImage} alt="img" />
        </div>
      )}
    </div>
  );
};

export default ImagesContainer;
