import React from "react";

import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const buttonItems = [
    {
      name: "Courses",
      path: "/courses",
    },
    {
      name: "Home",
      path: "/home",
    },
    {
      name: "About",
      path: "/about",
    },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="header_container">
      <div className="header_wrapper">
        {/* <div className="header_button_container">
          {buttonItems.map((item, index) => (
            <button
              key={index}
              onClick={() => handleNavigation(item.path)}
              className="header_button"
            >
              {item.name}
            </button>
          ))}
        </div> */}
        <Sidebar />
      </div>
    </div>
  );
};

export default Header;
