import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Mainlayout from "./Mainlayout";
import Home from "../pages/Home/Home";
import Courses from "../pages/Courses/Courses";
import Contact from "../pages/Contact";
import Faq from "../pages/Faq";
import AboutUs from "../pages/AboutUs";
import PageNotFound from "../pages/PageNotFound/PageNotFound";

function MainRoutes() {
  if (window.location.pathname === "/") {
    window.location.pathname = "/home";
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Mainlayout children={<Home />} />} />
        <Route
          path="/courses"
          element={<Mainlayout children={<Courses />} />}
        />
        <Route
          path="/contact"
          element={<Mainlayout children={<Contact />} />}
        />
        <Route path="/faq" element={<Mainlayout children={<Faq />} />} />
        <Route
          path="/aboutUs"
          element={<Mainlayout children={<AboutUs />} />}
        />

        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
