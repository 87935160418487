import { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuIcon from "@mui/icons-material/Menu";
import { Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      name: "Home",
      link: "/home",
      disabled: false,
    },
    {
      name: "About Us",
      link: "/aboutUs",
      disabled: false,
    },
    {
      name: "Courses",
      link: "/courses",
      disabled: false,
      subCategory: [
        {
          name: "Basics of Camera Handling",
          link: "/courses?course=1",
          disabled: false,
        },
        {
          name: "Fundamentals of Digital Photography & Media Arts Program",
          link: "/courses?course=2",
          disabled: false,
        },
      ],
    },
    {
      name: "Contact",
      link: "/contact",
      disabled: false,
    },
    {
      name: "Faqs",
      link: "/faq",
      disabled: false,
    },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [expanded, setExpanded] = useState(null);

  const handleShow = () => setSidebarOpen(!sidebarOpen);

  useEffect(() => {
    menuItems.forEach((item, index) => {
      if (item.link === location.pathname) {
        setActive(index);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClick = (index, item) => {
    setActive(index);
    setExpanded(expanded === index ? null : index);
    if (!item.subCategory) {
      navigate(item.link);
      setSidebarOpen(false);
    }
  };

  const handleSubItemClick = (link) => {
    navigate(link);
    setSidebarOpen(false);
  };

  return (
    <>
      <div className="sidebar_container">
        {!sidebarOpen && (
          <button className="ham_button" onClick={handleShow}>
            <MenuIcon />
          </button>
        )}
      </div>
      <Offcanvas
        className="sidebar"
        show={sidebarOpen}
        onHide={() => setSidebarOpen(false)}
        placement="end"
      >
        <Offcanvas.Header>
          <button
            type="button"
            className="close_button"
            onClick={() => setSidebarOpen(false)}
          >
            <HighlightOffIcon />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar_wrapper">
            {menuItems.map((item, index) => (
              <div key={index}>
                <div
                  className={`sidebar_item ${active === index ? "active" : ""}`}
                  onClick={() => handleClick(index, item)}
                >
                  {item.name}
                </div>
                {item.subCategory && (
                  <div
                    className={`sub_category ${
                      expanded === index ? "expanded" : ""
                    }`}
                  >
                    {item.subCategory.map((subItem, subIndex) => (
                      <div
                        style={{
                          cursor: subItem.disabled ? "not-allowed" : "",
                        }}
                        key={subIndex}
                        className="sidebar_item sub_item"
                        onClick={() => {
                          !subItem.disabled && handleSubItemClick(subItem.link);
                        }}
                      >
                        {subItem.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
