import React from "react";
import "./pageNotFound.css";

function PageNotFound() {
  return (
    <div className="not_found_container">
      Page Not Found
      <a href="/home">Back to home</a>
    </div>
  );
}

export default PageNotFound;
