import React, { useState } from "react";

import Image1 from "../../../assets/images/MG_6254.jpg";
import Logo from "../../../assets/images/Logo.png";
import { Link, useNavigate } from "react-router-dom";

const Course2 = () => {
  const navigate = useNavigate();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [copy, setCopy] = useState(false);
  const courseOptions = [
    {
      name: "+91 9163937992",
      type: "phone",
    },
    {
      name: "info@thefirstlight.in",
      type: "email",
    },
    {
      name: isEnrolled ? "Course Details" : "Course Fee",
      type: "course_fee",
    },
  ];

  const courseFeeInfo = [
    {
      field: "Beneficiary Name:",
      text: "Debarsi Goswami",
      copyable: false,
    },
    {
      field: "Beneficiary Bank Name:",
      text: "State Bank of India",
      copyable: false,
    },
    {
      field: "Beneficiary Account No.:",
      text: "34332937759",
      copyable: true,
    },
    {
      field: "RTGS/NEFT/ IFSC Code:",
      text: "SBIN0017458",
      copyable: true,
    },
    {
      field: "Account Type:",
      text: "Savings A/c",
      copyable: false,
    },
    {
      field: "UPI ID:",
      text: "goswamidebarsi@oksbi",
      copyable: true,
    },
  ];

  const handleOptionClick = (option) => {
    if (option.type === "phone") {
      window.open(`tel:${option.name}`);
    } else if (option.type === "email") {
      window.open(`mailto:${option.name}`);
    } else if (option.type === "course_fee") {
      setIsEnrolled(!isEnrolled);
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 2000);
        console.log("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="courses_wrapper">
      <div className="courses_title_container c2_tc">
        <div className="title">
          <img
            src={Logo}
            alt=""
            loading="lazy"
            style={{ cursor: "pointer", width: "215px" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <span>Fundamentals of Digital Photography</span>
          <span>& Media Arts Program</span>
        </div>
        <div className="title_img">
          <img src={Image1} loading="lazy" alt="" />
        </div>
      </div>
      <div className="title_key_highlights c2_kh">
        Key highlights:
        <span>
          Covers the Basic and Advanced concepts of Digital Photography through
          36 online sessions, Assignments, 1 Exam, 1 Project and 1 Portfolio.
        </span>
        <span>36-classes spread over 3-months.</span>
        <span>Certificate shall be issued upon completion of course</span>
      </div>
      <div className="courses_options_wrapper">
        <div>
          {courseOptions.map((option, index) => (
            <div key={index} onClick={() => handleOptionClick(option)}>
              {option.name}
            </div>
          ))}
        </div>
        <div>
          <span>
            Registration Open For Autumn Session
            {/* <Link to="/home#enquire">
              <button className="enquire_button">Enquire Now</button>
            </Link> */}
          </span>
        </div>
      </div>
      {isEnrolled ? (
        <div className="payment_wrapper">
          <div>Course Fee : &#8377; 14,999</div>
          <div className="payment_info">
            <>
              {copy && (
                <div className="copied_pop_up">Text copied to Clipboard</div>
              )}
              {courseFeeInfo.map((info, index) => (
                <div key={index}>
                  <span style={{ fontWeight: "400" }}>{info.field} </span>
                  <span
                    style={{ cursor: info.copyable ? "pointer" : "" }}
                    onClick={() => {
                      info.copyable && copyToClipboard(info.text);
                    }}
                  >
                    {info.text}
                  </span>
                </div>
              ))}
            </>
          </div>
        </div>
      ) : (
        <>
          <div className="courses_info_wrapper">
            <h2>Certified Course </h2>
            <div>
              <span>
                <label>Course Duration: </label> &nbsp; 12-weeks
              </span>
              <span>
                <label>Number of Sessions: </label> &nbsp;36
              </span>
              <span>
                <label>Number of Students: </label> &nbsp; 15 per Batch
              </span>
              <span>
                <label>Number of batch: </label> &nbsp;3
              </span>
              <span>
                <label>Session duration: </label> &nbsp;1 hour
              </span>
              <span>
                <label>
                  Session timing: <br />
                  <div>
                    Monday-Wednesday
                    <br />
                    Batch-1 : 11am - 12pm <br />
                    Batch-2 : 2pm - 3pm <br />
                    Batch-3 : 8pm - 9pm
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="courses_details_wrapper">
            <h2>Course Curriculum:</h2>
            <div>
              <span>
                <label>Basic camera operations & handling</label>
                <ul>
                  <li>Introduction to Photography</li>
                  <li>
                    Understanding of digital camera features and techniques
                  </li>
                  <li>Practical application of digital camera features</li>
                  <li>Basics of Photo editing and introduction to Photoshop</li>
                </ul>
              </span>
              <span>
                <label>Understanding framing & perspective</label>
                <ul>
                  <li>Fundamentals of framing</li>
                  <li>Different framing techniques</li>
                  <li>
                    Practical application of framing techniques & perspective
                  </li>
                  <li>Understanding camera lens</li>
                  <li>Understanding lens focal length</li>
                  <li>Classwork</li>
                </ul>
              </span>
              <span>
                <label>Lens aperture, f-stops, and allied concepts</label>
                <ul>
                  <li>Understanding lens Aperture</li>
                  <li>Understanding f-stops</li>
                  <li>Understanding shutter speed</li>
                  <li>Motion Blur and Light trails</li>
                  <li>Understanding ISO</li>
                  <li>Effect of ISO in composition</li>
                  <li>Practical application & techniques</li>
                </ul>
              </span>
              <span>
                <label>Fundamental concepts of digital camera exposure</label>
                <ul>
                  <li>Understanding exposure triangle</li>
                  <li>Controlling exposure in different scenarios</li>
                  <li>Fundamentals of colour</li>
                  <li>Understanding of colour Psychology</li>
                  <li>Practical application of colour modulation techniques</li>
                </ul>
              </span>
              <span>
                <label>
                  Fundamental concepts of camera white balance settings
                </label>
                <ul>
                  <li>Understanding camera white balance</li>
                  <li>Effects of white balance in composition</li>
                  <li>Understanding custom white balance</li>
                  <li>Effects of change of colour temperature</li>
                  <li>Practical application</li>
                </ul>
              </span>
              <span>
                <label>
                  Fundamental concepts of shooting modes & metering modes
                </label>
                <ul>
                  <li>Understanding camera metering modes</li>
                  <li>Digital camera shooting modes & techniques</li>
                </ul>
              </span>
              <span>
                <label>
                  Fundamental concepts of Photo editing and Retouching
                </label>
                <ul>
                  <li>Editing using Photoshop, Lightroom, and Capture One</li>
                  <li>Interactive sessions on concepts covered</li>
                  <li>Review of application & scenarios</li>
                  <li>Retouching</li>
                  <li>Different types of retouch processes</li>
                  <li>Model Vs Product (Retouch methods)</li>
                  <li>Advanced techniques</li>
                </ul>
              </span>
              <span>
                <label>Fundamental Cinematography</label>
                <ul>
                  <li>Basics of production pipeline</li>
                  <li>How a cinema works</li>
                  <li>Discussions on great films of the world</li>
                  <li>Basics of techniques used in cinematography</li>
                  <li>Positioning, Perspective and lighting</li>
                  <li>Shots and cuts</li>
                </ul>
              </span>
              <span>
                <label>Fundamentals of Light</label>
                <ul>
                  <li>How light works</li>
                  <li>Basics of controlling a light</li>
                  <li>Types of light and usefulness</li>
                  <li>Model Vs Product, How light changes</li>
                  <li>Virtual introduction to the studio lights</li>
                </ul>
              </span>
              <span>
                <label>Photojournalism and Street Photography:</label>
                <ul>
                  <li>Understanding Street Photography</li>
                  <li>Composition techniques</li>
                  <li>Introduction to Photojournalism</li>
                  <li>
                    Effects and Ethics of Photojournalism and Street Photography
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div className="courses_details_note">
            Discussion along with Audio Visual presentations and examinations at
            the end of the course will take place.Students will also need to
            submit a project and a portfolio at the end of the course in order
            to qualify.
            <div>
              ***Scheduled classes are subject to interchange depending on
              various factors.
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Course2;
