import React from "react";
import "./mainLayout.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";

export const Mainlayout = (props) => {
  const { children } = props;

  return (
    <div className="main_layout_wrapper">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Mainlayout;
