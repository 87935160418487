import React, { useEffect, useState } from "react";
import "./courses.css";
import Course1 from "./Course.js/Course1";
import Course2 from "./Course.js/Course2";
import { useLocation } from "react-router-dom";

const Courses = () => {
  const location = useLocation();
  const courseParam = new URL(window.location.href).searchParams.get("course");
  const [course, setCourse] = useState(courseParam);

  useEffect(() => {
    setCourse(courseParam);
  }, [location]);

  return (
    <div className="courses_main">
      {course === "1" ? <Course1 /> : course === "2" ? <Course2 /> : null}
    </div>
  );
};

export default Courses;
