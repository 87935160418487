import React from "react";
import Logo from "../../assets/images/Logo.png";
import "./leftPanel.css";
import { useNavigate } from "react-router-dom";

const LeftPanel = () => {
  const navigate = useNavigate();
  return (
    <div className="home_left_panel">
      <div>
        <div className="logo">
          <img
            src={Logo}
            alt=""
            loading="lazy"
            style={{ cursor: "pointer", width: "215px" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <div>Institute of Photography</div>
        </div>
        <p>
          First Light is an online platform for learning photography and opening
          to the possibilities to explore the creative world. Take the first
          step today with first light where we have devoted ourselves to share
          the knowledge and create better photographers for tomorrow.
        </p>
      </div>
      {/* Temporarily disabled */}
      {/* <div className="register_container" id="enquire">
        <h5>Registration Open Now</h5>
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Mail id" />
        <input type="text" placeholder="Phone Number" />
        <textarea type="text" placeholder="Query" />
        <button>Enquire Now</button>
      </div> */}
    </div>
  );
};

export default LeftPanel;
