import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact_main_div">
      <div className="contact_info_div">
        <div>
          Contact information <br />
          Phone number: +91 9163937992 <br />
          Email: info@thefirstlight.in
        </div>
        <div className="info2">Page Under Construction ...</div>
      </div>
    </div>
  );
};

export default Contact;
