import LeftPanel from "../../components/HomeLeftPanel/LeftPanel";
import ImagesContainer from "../../components/ImagesContainer/ImagesContainer";

import "./home.css";

const Home = () => {
  return (
    <div className="home_main_wrapper">
      <LeftPanel />
      <div className="image_grid">
        <ImagesContainer />
      </div>
    </div>
  );
};
export default Home;
