import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Logo from "../../assets/images/Logo.png";

import "./faq.css";
import { useNavigate } from "react-router-dom";

const faqContent = [
  {
    title: "Can you learn Photography online?",
    description:
      "Yes and no. You can definitely learn the basics and some of the advanced aspects of photography through online classes, which will be enough for you to shoot better photographs. But if you want to learn super advanced techniques of studio, camera handling, and professional tips and tricks, it requires you to be present in the studio. Our classes are customized towards your basic learning.",
  },
  {
    title: "Why choose us?",
    description:
      "First Light is an online institute where you can learn the basics and advanced aspects of photography from the comfort of your home. It's a student-centered learning opportunity where we will walk you through the meadows of art.",
  },
  {
    title: "Are there pre-recorded videos for these classes?",
    description:
      "There are NO pre-recorded videos. All the classes are live, interactive, and catered towards your needs.",
  },
  {
    title:
      "I want to become a Professional Photographer. Are these classes good for me?",
    description:
      "Again, Yes and No. While you can learn the basics of photography and some of the advanced aspects through our online classes, these classes will not be enough for you to become a professional in this field. Our suggestion would be to take the 3-month basic course from us without burning a hole in your pocket and judge for yourself if you want to progress further in this field.",
  },
  {
    title: "What qualifications do I need to apply for classes?",
    description:
      "10+2 is the minimum qualification. You need to understand English as all the classes will be held in English. You need to have a stable internet connection and a webcam for the classes.",
  },
  {
    title: "What will I get upon the completion of the courses?",
    description:
      "Upon completing the 1-month course you will learn to take better photographs, you will have a handful of good pictures at your disposal which you can use to start building your portfolio if you want to progress further. This course does not have a certificate. Upon completing the 3-month course you will learn to take much better photographs. A certificate will be provided by the institute. We will help you build a portfolio. You will have insight into the industry and you will learn the basics of cinematography as well.",
  },
  {
    title: "Do I need a camera to start the course?",
    description:
      "Though it is highly encouraged to have a camera before you start your photography journey with us, it's not mandatory to have a camera right away. But during our courses, you will learn much better if you have a camera and can practice the lessons taught every day.",
  },
];

const Faq = () => {
  const [show, setShow] = useState([]);
  const navigate = useNavigate();

  return (
    <div className="faq_main_div">
      <img
        src={Logo}
        alt=""
        loading="lazy"
        style={{ cursor: "pointer", width: "215px", paddingLeft: "5vw" }}
        onClick={() => {
          navigate("/home");
        }}
      />
      <div className="faq_title">
        <div className="faq_sub">F.A.Q</div>
        <div className="faq_main">
          Here are some common questions about learning photography online.
        </div>
      </div>
      <div className="faq_div">
        {faqContent.map((item, index) => (
          <div key={index} className="faq_question_div">
            <div className="faq_question_title">
              {item.title}
              <span
                style={{
                  transform: show[index] ? "rotate(90deg)" : "rotate(0deg)",
                }}
                onClick={() => {
                  setShow((prev) => {
                    const newShow = [...prev];
                    newShow[index] = !newShow[index];
                    return newShow;
                  });
                }}
              >
                <ArrowForwardIosIcon />
              </span>
            </div>
            <div
              className={`faq_question_description ${
                show[index] ? "show_faq" : ""
              }`}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
