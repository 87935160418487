import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer_wrapper">
      &copy; 2024 All rights reserved. Registered under Govt. of India
    </div>
  );
};

export default Footer;
