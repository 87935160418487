import React from "react";
import People1 from "../../assets/images/AboutUs/souvratro_banerjee.jpg";
import People2 from "../../assets/images/AboutUs/nilarun_basu.jpeg";
import People3 from "../../assets/images/AboutUs/debarsi_goswami.jpg";
import Logo from "../../assets/images/Logo.png";

import "./aboutUs.css";
import { useNavigate } from "react-router-dom";

const people = [
  {
    name: "Souvratro Banerjee",
    img: People1,
    description:
      "Souvratro Banerjee is the Head of Design at an established advertising agency. He brings over a decade of experience as a Photoshop expert in the advertising industry. He has honed his skills in creating compelling visual narratives that captivate audiences. His journey in the world of digital artistry has been driven by a passion for innovation and a commitment to excellence, always pushing the boundaries of what’s possible with Photoshop. He is an excellent addition to the organisation to share his knowledge and insights with aspiring photographers, helping them unlock their creative potential and master the art of digital imaging.",
  },
  {
    name: "Nilarun Basu",
    img: People2,
    description:
      "Nilarun Basu is a visual storyteller with a decade's worth of experience in professional photography. Under the umbrella of his company Candid Affair, he has shot for a wide variety of clients over the years. From fashion to events and corporate films, he has worked on a wide variety of projects. He is also a published author. His maiden feature film is currently doing the film festival rounds across the world and is slated for a theatrical release later this year. Nilarun is a pride possession of the organisation when it comes to sharing knowledge about cinematography, visual aesthetics and providing industry insight to aspiring photographers.",
  },
  {
    name: "Debarsi Goswami",
    img: People3,
    description:
      "Debarsi Goswami, founder and owner of the Institution, brings over 7 years of academic experience in Photography and Journalism/Mass Communication. Starting his journey as a budding photographer in 2013, he has worked with a wide array of clients, ranging from fashion to product, travel, advertising, and worked as a photojournalist in a reputed news agency as well. Switching over to the academic side of the artform in 2017, he taught as a lecturer of Photography/Journalism at iCat Design and Media College as well as Amity University for all 7 years while maintaining his guest lectureship in IIPK Kolkata. He has taken a multitude of workshops throughout Kolkata, Chennai and Bangalore. His teaching style embraces an interactive environment instead of another boring lecture to listen to. He has dedicated himself to sharing knowledge with aspiring photographers and nurturing them with every aspect of his teaching and caring.",
  },
];

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div className="aboutUs_main_div">
      <img
        src={Logo}
        alt=""
        loading="lazy"
        style={{ cursor: "pointer", width: "215px" }}
        onClick={() => {
          navigate("/home");
        }}
      />
      <div className="aboutUs_title">
        <div className="t1">About us</div>
        <div className="aboutUs_description">
          First Light is an online platform dedicated to share knowledge with
          aspiring photographers and mentor the budding talents in art. We
          believe in the mantra “Everyone is an artist if nurtured properly.”
          Our journey starts with a handful of exceptionally talented people who
          will guide and help the aspirants achieve their goal and successfully
          express themselves in the artform.
        </div>
      </div>
      <div className="aboutUs_people">
        <div className="aboutUs_people_title">Let's meet our faculties.</div>
        <div className="aboutUs_people_description">
          {people.map((item, index) => (
            <div key={index} className="aboutUs_people_card">
              <img
                className="aboutUs_people_img"
                src={item.img}
                alt="ppl_img"
              />
              <div className="aboutUs_people_info">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
