import React, { useState } from "react";

import Image1 from "../../../assets/images/MG_3294.jpg";
import Logo from "../../../assets/images/Logo.png";
import { Link, useNavigate } from "react-router-dom";

const Course1 = () => {
  const navigate = useNavigate();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [copy, setCopy] = useState(false);
  const courseOptions = [
    {
      name: "+91 9163937992",
      type: "phone",
    },
    {
      name: "info@thefirstlight.in",
      type: "email",
    },
    {
      name: isEnrolled ? "Course Details" : "Course Fee",
      type: "course_fee",
    },
  ];

  const courseFeeInfo = [
    {
      field: "Beneficiary Name:",
      text: "Debarsi Goswami",
      copyable: false,
    },
    {
      field: "Beneficiary Bank Name:",
      text: "State Bank of India",
      copyable: false,
    },
    {
      field: "Beneficiary Account No.:",
      text: "34332937759",
      copyable: true,
    },
    {
      field: "RTGS/NEFT/ IFSC Code:",
      text: "SBIN0017458",
      copyable: true,
    },
    {
      field: "Account Type:",
      text: "Savings A/c",
      copyable: false,
    },
    {
      field: "UPI ID:",
      text: "goswamidebarsi@oksbi",
      copyable: true,
    },
  ];

  const handleOptionClick = (option) => {
    if (option.type === "phone") {
      window.open(`tel:${option.name}`);
    } else if (option.type === "email") {
      window.open(`mailto:${option.name}`);
    } else if (option.type === "course_fee") {
      setIsEnrolled(!isEnrolled);
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 2000);
        console.log("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="courses_wrapper">
      <div className="courses_title_container">
        <div className="title">
          <img
            src={Logo}
            alt=""
            loading="lazy"
            style={{ cursor: "pointer", width: "215px" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <span>Basics of</span>
          <span>Camera Handling</span>
          <div className="title_key_highlights">
            Key highlights:
            <span>
              Covers the basic concepts of digital photography, basic editing
              through 8 online interactive sessions
            </span>
            <span>8-Sessions spread over 1-month.</span>
          </div>
        </div>
        <div className="title_img">
          <img src={Image1} loading="lazy" alt="" />
        </div>
      </div>
      <div className="courses_options_wrapper">
        <div>
          {courseOptions.map((option, index) => (
            <div key={index} onClick={() => handleOptionClick(option)}>
              {option.name}
            </div>
          ))}
        </div>
        <div>
          <span>
            Registration Open for next month
            {/* <Link to="/home#enquire">
              <button className="enquire_button">Enquire Now</button>
            </Link> */}
          </span>
        </div>
      </div>
      {isEnrolled ? (
        <div className="payment_wrapper">
          <div>Course Fee : &#8377; 3,999</div>
          <div className="payment_info">
            <>
              {copy && (
                <div className="copied_pop_up">Text copied to Clipboard</div>
              )}
              {courseFeeInfo.map((info, index) => (
                <div key={index}>
                  <span style={{ fontWeight: "400" }}>{info.field} </span>
                  <span
                    style={{ cursor: info.copyable ? "pointer" : "" }}
                    onClick={() => {
                      info.copyable && copyToClipboard(info.text);
                    }}
                  >
                    {info.text}
                  </span>
                </div>
              ))}
            </>
          </div>
        </div>
      ) : (
        <>
          <div className="courses_info_wrapper">
            <h2>Non-Certified Course </h2>
            <div>
              <span>
                <label>Course Duration: </label> &nbsp; 4-weeks
              </span>
              <span>
                <label>Number of Sessions: </label> &nbsp;8
              </span>
              <span>
                <label>Number of Students: </label> &nbsp; 20 per Batch
              </span>
              <span>
                <label>Number of batch: </label> &nbsp;2
              </span>
              <span>
                <label>Session duration: </label> &nbsp;1 hour
              </span>
              <span>
                <label>
                  Session timing: <br />
                  <div>
                    Saturday & Sunday
                    <br />
                    Batch-1 : 2pm - 3pm <br />
                    Batch-2 : 6pm - 7pm
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="courses_details_wrapper">
            <h2>Course Curriculum:</h2>
            <div>
              <span>
                <label>Week 1</label>
                <ul>
                  <li>Basic camera operations & handling</li>
                  <li>Fundamental operations of Digital Camera</li>
                  <li>
                    Understanding of digital camera features and techniques
                  </li>
                  <li>Practical application of digital camera features</li>
                </ul>
              </span>
              <span>
                <label>Week 2</label>
                <ul>
                  <li>Understanding composition Fundamentals</li>
                  <li>Composition rules & techniques </li>
                  <li>Practical application of composition techniques</li>
                </ul>
              </span>
              <span>
                <label>Week 3</label>
                <ul>
                  <li>Understanding Technical aspects</li>
                  <li>Aperture</li>
                  <li>ISO</li>
                  <li>Shutter speed</li>
                </ul>
              </span>
              <span>
                <label>Week 4</label>
                <ul>
                  <li>Fundamental concepts of camera lens & operations</li>
                  <li> Exposure triangle</li>
                  <li>Understanding focal length</li>
                  <li>Practical application & scenarios</li>
                </ul>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Course1;
